








































import { Component, Vue } from 'vue-property-decorator';
import SpecialDepositSuccessViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/special-deposits/special-deposit-success-view-model';

@Component({ name: 'SpecialDepositSuccess' })
export default class SpecialDepositSuccess extends Vue {
  special_deposit_success_view_model = Vue.observable(
    new SpecialDepositSuccessViewModel(this),
  );
}
